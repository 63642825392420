import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const PlayfulStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Playful]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Caveat&display=swap"
        );
        loadFont("https://fonts.googleapis.com/css2?family=Lato&display=swap");
    }, []);

    return (
        <div style={{ fontFamily: "Caveat" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "161px",
                        fontWeight: 700,
                        fontSize: "120px",
                        width: "1080px",
                        padding: "0px 102px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "1559px",
                        fontFamily: "Lato",
                        fontWeight: 600,
                        fontSize: "40px",
                        width: "1080px",
                        padding: "0px 118px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        bottom: "0px",
                        width: "1080px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                    }}
                >
                    <div
                        style={{
                            color: color2,
                            backgroundColor: color3,
                            fontWeight: 400,
                            fontSize: "96px",
                            padding: "2px 27px",
                            wordBreak: "break-word",
                        }}
                    >
                        {textData?.action_text}
                    </div>
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "446px",
                        height: "684px",
                        right: "317px",
                        bottom: "618px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "25px",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_2241)">
                    <rect width="1080" height="1920" fill={color1} />
                    <path
                        d="M846.56 673.406V646.311M833.12 659.858H860H833.12Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M846.56 606.707V579.611M833.12 593.159H860H833.12Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M846.56 542.096V515M833.12 528.548H860H833.12Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M846.56 673.406V646.311M833.12 659.858H860H833.12Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M846.56 606.707V579.611M833.12 593.159H860H833.12Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M846.56 542.096V515M833.12 528.548H860H833.12Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 673.406V646.311M771.085 659.858H797.965H771.085Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 606.707V579.611M771.085 593.159H797.965H771.085Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 542.096V515M771.085 528.548H797.965H771.085Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 673.406V646.311M771.085 659.858H797.965H771.085Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 606.707V579.611M771.085 593.159H797.965H771.085Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M784.525 542.096V515M771.085 528.548H797.965H771.085Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M722.499 606.707V579.611M709.059 593.159H735.938H709.059Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M722.499 542.096V515M709.059 528.548H735.938H709.059Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M722.499 606.707V579.611M709.059 593.159H735.938H709.059Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M722.499 542.096V515M709.059 528.548H735.938H709.059Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M660.463 606.707V579.611M647.023 593.159H673.903H647.023Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M660.463 542.096V515M647.023 528.548H673.903H647.023Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M660.463 606.707V579.611M647.023 593.159H673.903H647.023Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M660.463 542.096V515M647.023 528.548H673.903H647.023Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M598.44 606.707V579.611M585.001 593.159H611.88H585.001Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M598.44 542.096V515M585.001 528.548H611.88H585.001Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M598.44 606.707V579.611M585.001 593.159H611.88H585.001Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M598.44 542.096V515M585.001 528.548H611.88H585.001Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1246V1273.1M246.88 1259.55H220H246.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1312.7V1339.79M246.88 1326.25H220H246.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1377.31V1404.41M246.88 1390.86H220H246.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1246V1273.1M246.88 1259.55H220H246.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1312.7V1339.79M246.88 1326.25H220H246.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M233.44 1377.31V1404.41M246.88 1390.86H220H246.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1246V1273.1M308.914 1259.55H282.034H308.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1312.7V1339.79M308.914 1326.25H282.034H308.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1377.31V1404.41M308.914 1390.86H282.034H308.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1246V1273.1M308.914 1259.55H282.034H308.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1312.7V1339.79M308.914 1326.25H282.034H308.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M295.474 1377.31V1404.41M308.914 1390.86H282.034H308.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M357.499 1312.7V1339.79M370.939 1326.25H344.059H370.939Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M357.499 1377.31V1404.41M370.939 1390.86H344.059H370.939Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M357.499 1312.7V1339.79M370.939 1326.25H344.059H370.939Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M357.499 1377.31V1404.41M370.939 1390.86H344.059H370.939Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M419.533 1312.7V1339.79M432.973 1326.25H406.093H432.973Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M419.533 1377.31V1404.41M432.973 1390.86H406.093H432.973Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M419.533 1312.7V1339.79M432.973 1326.25H406.093H432.973Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M419.533 1377.31V1404.41M432.973 1390.86H406.093H432.973Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M481.554 1312.7V1339.79M494.994 1326.25H468.114H494.994Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M481.554 1377.31V1404.41M494.994 1390.86H468.114H494.994Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M481.554 1312.7V1339.79M494.994 1326.25H468.114H494.994Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M481.554 1377.31V1404.41M494.994 1390.86H468.114H494.994Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M82.0103 -97.3139C147.227 -63.4627 182.754 12.0514 163.414 86.1136C142.499 166.203 64.8142 216.366 -15.2062 204.739"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M101.314 -141.185C187.681 -97.9682 235.065 1.06949 209.692 98.2307C182.424 202.65 80.7473 267.885 -23.6554 251.913"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M63.9772 -53.4492C107.684 -28.6195 131.111 23.2737 117.802 74.2367C103.199 130.154 49.4494 165.466 -6.41223 158.395"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M45.7355 -9.57103C68.205 5.97098 79.7261 34.3955 72.4166 62.3858C64.0853 94.289 34.0641 114.764 2.31445 112.169"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M979.117 181.229C980.648 179.636 983.18 179.587 984.772 181.118L1008 203.452L1031.23 181.118C1032.82 179.587 1035.35 179.636 1036.88 181.229C1038.41 182.821 1038.36 185.353 1036.77 186.884L1012.16 210.552C1009.84 212.785 1006.16 212.785 1003.84 210.552L979.228 186.884C977.635 185.353 977.585 182.821 979.117 181.229ZM979.117 146.229C980.648 144.636 983.18 144.587 984.772 146.118L1008 168.452L1031.23 146.118C1032.82 144.587 1035.35 144.636 1036.88 146.229C1038.41 147.821 1038.36 150.353 1036.77 151.884L1012.16 175.552C1009.84 177.785 1006.16 177.785 1003.84 175.552L979.228 151.884C977.635 150.353 977.585 147.821 979.117 146.229ZM984.772 111.118C983.18 109.587 980.648 109.636 979.117 111.229C977.585 112.821 977.635 115.353 979.228 116.884L1003.84 140.552C1006.16 142.785 1009.84 142.785 1012.16 140.552L1036.77 116.884C1038.36 115.353 1038.41 112.821 1036.88 111.229C1035.35 109.636 1032.82 109.587 1031.23 111.118L1008 133.452L984.772 111.118ZM979.117 76.2287C980.648 74.6363 983.18 74.5866 984.772 76.1178L1008 98.452L1031.23 76.1178C1032.82 74.5866 1035.35 74.6363 1036.88 76.2287C1038.41 77.8211 1038.36 80.3533 1036.77 81.8845L1012.16 105.552C1009.84 107.785 1006.16 107.785 1003.84 105.552L979.228 81.8845C977.635 80.3533 977.585 77.8211 979.117 76.2287ZM984.772 41.1178C983.18 39.5866 980.648 39.6363 979.117 41.2287C977.585 42.8211 977.635 45.3533 979.228 46.8845L1003.84 70.5516C1006.16 72.7849 1009.84 72.7849 1012.16 70.5516L1036.77 46.8845C1038.36 45.3533 1038.41 42.8211 1036.88 41.2287C1035.35 39.6363 1032.82 39.5866 1031.23 41.1178L1008 63.452L984.772 41.1178Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M112.978 1719.52C111.721 1718.82 111.576 1717.57 112.653 1716.74L139.284 1696.28C140.246 1695.55 140.246 1694.45 139.284 1693.72L112.653 1673.26C111.576 1672.43 111.721 1671.18 112.978 1670.48C114.235 1669.77 116.128 1669.86 117.205 1670.69L143.835 1691.15C146.722 1693.36 146.722 1696.64 143.835 1698.85L117.205 1719.31C116.128 1720.14 114.235 1720.23 112.978 1719.52ZM73.0126 1719.52C71.7557 1718.82 71.6101 1717.57 72.6875 1716.74L99.3177 1696.28C100.28 1695.55 100.28 1694.45 99.3177 1693.72L72.6875 1673.26C71.6101 1672.43 71.7557 1671.18 73.0126 1670.48C74.2695 1669.77 76.1618 1669.86 77.2391 1670.69L103.869 1691.15C106.756 1693.36 106.756 1696.64 103.869 1698.85L77.2391 1719.31C76.1618 1720.14 74.2695 1720.23 73.0126 1719.52ZM32.7217 1716.74C31.6443 1717.57 31.7899 1718.82 33.0468 1719.52C34.3037 1720.23 36.196 1720.14 37.2733 1719.31L63.9036 1698.85C66.79 1696.64 66.79 1693.36 63.9036 1691.15L37.2733 1670.69C36.196 1669.86 34.3037 1669.77 33.0468 1670.48C31.7899 1671.18 31.6443 1672.43 32.7217 1673.26L59.3519 1693.72C60.3141 1694.45 60.3141 1695.55 59.3519 1696.28L32.7217 1716.74Z"
                        fill={color2}
                    />
                    <path
                        d="M923 1405C923 1371.86 949.863 1345 983 1345C1016.14 1345 1043 1371.86 1043 1405C1043 1438.14 1016.14 1465 983 1465C949.863 1465 923 1438.14 923 1405Z"
                        stroke={color3}
                        stroke-width="30"
                    />
                    <circle
                        cx="818"
                        cy="1475"
                        r="20"
                        transform="rotate(-180 818 1475)"
                        fill={color3}
                    />
                    <circle
                        cx="828"
                        cy="1303"
                        r="27.5"
                        transform="rotate(-180 828 1303)"
                        stroke={color3}
                        stroke-width="5"
                    />
                    <path
                        d="M55.3808 1774H0V1825.66H55.3808V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M157.838 1774H102.457V1825.66H157.838V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M260.245 1774H204.864V1825.66H260.245V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M362.685 1774H307.304V1825.66H362.685V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M465.091 1774H409.71V1825.66H465.091V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M567.515 1774H512.134V1825.66H567.515V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M669.955 1774H614.574V1825.66H669.955V1774Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M55.3808 1858.29H0V1909.96H55.3808V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M157.838 1858.29H102.457V1909.96H157.838V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M260.245 1858.29H204.864V1909.96H260.245V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M362.685 1858.29H307.304V1909.96H362.685V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M465.091 1858.29H409.71V1909.96H465.091V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M567.515 1858.29H512.134V1909.96H567.515V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M669.955 1858.29H614.574V1909.96H669.955V1858.29Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M771.428 1774.04H716.047V1825.7H771.428V1774.04Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M873.885 1774.04H818.504V1825.7H873.885V1774.04Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M976.291 1774.04H920.91V1825.7H976.291V1774.04Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1078.73 1774.04H1023.35V1825.7H1078.73V1774.04Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M771.428 1858.33H716.047V1909.99H771.428V1858.33Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M873.885 1858.33H818.504V1909.99H873.885V1858.33Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M976.291 1858.33H920.91V1909.99H976.291V1858.33Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1078.73 1858.33H1023.35V1909.99H1078.73V1858.33Z"
                        fill={color3}
                        stroke={color3}
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_689_2241">
                        <rect width="1080" height="1920" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
