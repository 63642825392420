import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const ThiverPostSimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Thiver]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Ubuntu" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        bottom: "155px",
                        right: "50px",
                        fontWeight: 700,
                        fontStyle: "italic",
                        fontSize: "100px",
                        width: "716px",
                        wordBreak: "break-word",
                        textTransform: "uppercase",
                        zIndex: 1,
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        backgroundColor: color2,
                        color: "#0D0C0C",
                        top: "938px",
                        right: "50px",
                        fontWeight: 400,
                        fontSize: "36px",
                        width: "716px",
                        wordBreak: "break-word",
                        zIndex: 1,
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "93px",
                        right: "50px",
                        fontWeight: 700,
                        fontStyle: "italic",
                        fontSize: "64px",
                        wordBreak: "break-word",
                        textTransform: "uppercase",
                        zIndex: 1,
                        width: "460px",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "392px",
                        height: "890px",
                        top: "190px",
                        left: "148px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_59_149)">
                    <rect width="1080" height="1080" fill={color1} />
                    <path
                        d="M1129.47 725.722C1132.53 728.409 1134.39 733.144 1134.39 738.242L1134.39 814.758C1134.39 826.307 1125.43 833.366 1118.5 827.278L1035.84 754.666C1032.49 751.715 1028.24 751.715 1024.88 754.666L942.22 827.278C935.291 833.366 926.334 826.307 926.334 814.758V738.242C926.334 733.144 928.195 728.409 931.254 725.722L1024.88 643.476C1028.24 640.525 1032.49 640.525 1035.84 643.476L1129.47 725.722Z"
                        fill={color3}
                    />
                    <path
                        d="M1129.47 891.465C1132.53 894.152 1134.39 898.887 1134.39 903.984L1134.39 980.501C1134.39 992.05 1125.43 999.109 1118.5 993.021L1035.84 920.409C1032.49 917.458 1028.24 917.458 1024.88 920.409L942.22 993.021C935.291 999.109 926.334 992.05 926.334 980.501V903.984C926.334 898.887 928.195 894.152 931.254 891.465L1024.88 809.219C1028.24 806.268 1032.49 806.268 1035.84 809.219L1129.47 891.465Z"
                        fill={color3}
                    />
                    <path
                        d="M1129.47 1057.21C1132.53 1059.89 1134.39 1064.63 1134.39 1069.73L1134.39 1146.24C1134.39 1157.79 1125.43 1164.85 1118.5 1158.76L1035.84 1086.15C1032.49 1083.2 1028.24 1083.2 1024.88 1086.15L942.22 1158.76C935.291 1164.85 926.334 1157.79 926.334 1146.24V1069.73C926.334 1064.63 928.195 1059.89 931.254 1057.21L1024.88 974.961C1028.24 972.011 1032.49 972.011 1035.84 974.961L1129.47 1057.21Z"
                        fill={color3}
                    />
                    <path
                        d="M1109.81 688.962C1112.34 691.177 1114.05 695.3 1114.05 699.979L1114.05 776.495C1114.05 781.791 1112 785.806 1109.47 787.798C1108.22 788.785 1106.88 789.261 1105.59 789.251C1104.31 789.242 1102.9 788.756 1101.49 787.513L1018.83 714.901C1016.83 713.143 1014.47 712.19 1012.03 712.19C1009.58 712.19 1007.22 713.143 1005.22 714.901L922.566 787.513C921.151 788.756 919.744 789.242 918.465 789.251C917.172 789.261 915.834 788.785 914.582 787.798C912.054 785.806 910 781.791 910 776.496V699.979C910 695.3 911.718 691.177 914.239 688.962L1007.86 606.716C1009.22 605.523 1010.67 605 1012.03 605C1013.39 605 1014.83 605.523 1016.19 606.716L1109.81 688.962ZM1109.81 854.704C1112.34 856.919 1114.05 861.043 1114.05 865.722L1114.05 942.238C1114.05 947.534 1112 951.549 1109.47 953.541C1108.22 954.528 1106.88 955.004 1105.59 954.994C1104.31 954.984 1102.9 954.499 1101.49 953.256L1018.83 880.644C1016.83 878.886 1014.47 877.933 1012.03 877.933C1009.58 877.933 1007.22 878.886 1005.22 880.644L922.566 953.256C921.151 954.499 919.744 954.984 918.465 954.994C917.172 955.004 915.834 954.528 914.582 953.541C912.054 951.549 910 947.534 910 942.238V865.722C910 861.043 911.718 856.919 914.239 854.704L1007.86 772.459C1009.22 771.266 1010.67 770.743 1012.03 770.743C1013.39 770.743 1014.83 771.266 1016.19 772.459L1109.81 854.704ZM1109.81 1020.45C1112.34 1022.66 1114.05 1026.79 1114.05 1031.46L1114.05 1107.98C1114.05 1113.28 1112 1117.29 1109.47 1119.28C1108.22 1120.27 1106.88 1120.75 1105.59 1120.74C1104.31 1120.73 1102.9 1120.24 1101.49 1119L1018.83 1046.39C1016.83 1044.63 1014.47 1043.68 1012.03 1043.68C1009.58 1043.68 1007.22 1044.63 1005.22 1046.39L922.566 1119C921.151 1120.24 919.744 1120.73 918.465 1120.74C917.172 1120.75 915.834 1120.27 914.582 1119.28C912.054 1117.29 910 1113.28 910 1107.98V1031.46C910 1026.79 911.718 1022.66 914.239 1020.45L1007.86 938.201C1009.22 937.009 1010.67 936.486 1012.03 936.486C1013.39 936.486 1014.83 937.009 1016.19 938.201L1109.81 1020.45Z"
                        stroke={color2}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <path
                        d="M20.7623 560.285C16.5577 556.827 14 550.732 14 544.17L14 445.679C14 430.813 26.3116 421.727 35.8377 429.563L149.462 523.029C154.079 526.827 159.921 526.827 164.538 523.029L278.162 429.563C287.688 421.727 300 430.813 300 445.679V544.17C300 550.732 297.442 556.827 293.238 560.285L164.538 666.152C159.921 669.949 154.079 669.949 149.462 666.152L20.7623 560.285Z"
                        fill={color3}
                    />
                    <path
                        d="M20.7623 346.943C16.5577 343.484 14 337.389 14 330.828L14 232.336C14 217.47 26.3116 208.385 35.8377 216.221L149.462 309.686C154.079 313.484 159.921 313.484 164.538 309.686L278.162 216.221C287.688 208.385 300 217.47 300 232.336V330.828C300 337.389 297.442 343.484 293.238 346.943L164.538 452.809C159.921 456.607 154.079 456.607 149.462 452.809L20.7623 346.943Z"
                        fill={color3}
                    />
                    <path
                        d="M20.7623 133.601C16.5577 130.142 14 124.047 14 117.485L14 18.9939C14 4.12804 26.3116 -4.95743 35.8377 2.87859L149.462 96.344C154.079 100.142 159.921 100.142 164.538 96.344L278.162 2.87859C287.688 -4.95743 300 4.12801 300 18.9939V117.485C300 124.047 297.442 130.142 293.238 133.601L164.538 239.467C159.921 243.265 154.079 243.265 149.462 239.467L20.7623 133.601Z"
                        fill={color3}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 863L591 908H642.962L616.981 863ZM616.981 871L597.928 904H636.033L616.981 871Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 923L591 968H642.962L616.981 923ZM616.981 931L597.928 964H636.033L616.981 931Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 983L642.962 1028H591L616.981 983ZM597.928 1024L616.981 991L636.033 1024H597.928Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.98 472L642.961 427H590.999L616.98 472ZM616.98 464L636.033 431H597.928L616.98 464Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.98 412L642.961 367H590.999L616.98 412ZM616.98 404L636.033 371H597.928L616.98 404Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.98 352L590.999 307H642.961L616.98 352ZM636.033 311L616.98 344L597.928 311H636.033Z"
                        fill={color2}
                    />
                    <rect
                        x="60"
                        y="93"
                        width="480"
                        height="1704"
                        fill={color2}
                    />
                    <path
                        d="M197.845 807.049C199.47 808.387 200.5 810.792 200.5 813.432L200.5 854.802C200.5 857.792 199.264 860.146 197.62 861.36C196.001 862.556 193.964 862.674 192.155 861.185L144.481 821.926C142.359 820.179 139.641 820.179 137.519 821.926L89.8448 861.185C88.0357 862.674 85.9993 862.556 84.3796 861.36C82.7363 860.146 81.5 857.792 81.5 854.802V813.432C81.5 810.792 82.5302 808.387 84.1552 807.049L138.155 762.582C139.908 761.139 142.092 761.139 143.845 762.582L197.845 807.049ZM197.845 896.66C199.47 897.998 200.5 900.402 200.5 903.042L200.5 944.412C200.5 947.402 199.264 949.756 197.62 950.97C196.001 952.167 193.964 952.285 192.155 950.795L144.481 911.536C142.359 909.789 139.641 909.789 137.519 911.536L89.8448 950.795C88.0357 952.285 85.9993 952.167 84.3796 950.97C82.7363 949.756 81.5 947.402 81.5 944.412V903.042C81.5 900.402 82.5302 897.998 84.1552 896.66L138.155 852.193C139.908 850.749 142.092 850.749 143.845 852.193L197.845 896.66ZM197.845 986.27C199.47 987.608 200.5 990.012 200.5 992.653L200.5 1034.02C200.5 1037.01 199.264 1039.37 197.62 1040.58C196.001 1041.78 193.964 1041.89 192.155 1040.4L144.481 1001.15C142.359 999.399 139.641 999.399 137.519 1001.15L89.8448 1040.4C88.0357 1041.89 85.9993 1041.78 84.3796 1040.58C82.7363 1039.37 81.5 1037.01 81.5 1034.02V992.653C81.5 990.012 82.5302 987.608 84.1552 986.27L138.155 941.803C139.908 940.36 142.092 940.36 143.845 941.803L197.845 986.27Z"
                        stroke="#0D0C0C"
                        stroke-linecap="round"
                    />
                    <path
                        d="M84.1552 342.951C82.5302 341.613 81.5 339.208 81.5 336.568L81.5 295.198C81.5 292.208 82.7363 289.854 84.3796 288.64C85.9993 287.444 88.0357 287.326 89.8448 288.815L137.519 328.074C139.641 329.821 142.359 329.821 144.481 328.074L192.155 288.815C193.964 287.326 196.001 287.444 197.62 288.64C199.264 289.854 200.5 292.208 200.5 295.198V336.568C200.5 339.208 199.47 341.613 197.845 342.951L143.845 387.418C142.092 388.861 139.908 388.861 138.155 387.418L84.1552 342.951ZM84.1552 253.34C82.5302 252.002 81.5 249.598 81.5 246.958L81.5 205.588C81.5 202.598 82.7363 200.244 84.3796 199.03C85.9993 197.833 88.0357 197.715 89.8448 199.205L137.519 238.464C139.641 240.211 142.359 240.211 144.481 238.464L192.155 199.205C193.964 197.715 196.001 197.833 197.62 199.03C199.264 200.244 200.5 202.598 200.5 205.588V246.958C200.5 249.598 199.47 252.002 197.845 253.34L143.845 297.807C142.092 299.251 139.908 299.251 138.155 297.807L84.1552 253.34ZM84.1552 163.73C82.5302 162.392 81.5 159.988 81.5 157.347L81.5 115.978C81.5 112.988 82.7363 110.634 84.3796 109.42C85.9994 108.223 88.0357 108.105 89.8448 109.595L137.519 148.853C139.641 150.601 142.359 150.601 144.481 148.853L192.155 109.595C193.964 108.105 196.001 108.223 197.62 109.42C199.264 110.634 200.5 112.988 200.5 115.978V157.347C200.5 159.988 199.47 162.392 197.845 163.73L143.845 208.197C142.092 209.64 139.908 209.64 138.155 208.197L84.1552 163.73Z"
                        stroke="#0D0C0C"
                        stroke-linecap="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_59_149">
                        <rect width="1080" height="1080" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
