import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const PlayfulPostSimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Playful]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Caveat&display=swap"
        );
        loadFont("https://fonts.googleapis.com/css2?family=Lato&display=swap");
    }, []);

    return (
        <div style={{ fontFamily: "Caveat" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        bottom: "699px",
                        fontWeight: 700,
                        fontSize: "90px",
                        width: "1080px",
                        padding: "0px 156px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "400px",
                        fontFamily: "Lato",
                        fontWeight: 600,
                        fontSize: "36px",
                        width: "1080px",
                        padding: "0px 219px",
                        wordBreak: "break-word",
                        textAlign: "center",
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        backgroundColor: color3,
                        top: "635px",
                        left: "104px",
                        fontWeight: 400,
                        fontSize: "96px",
                        padding: "2px 27px",
                        wordBreak: "break-word",
                        maxWidth: "580px",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "314px",
                        height: "482px",
                        right: "41px",
                        bottom: "58px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        borderRadius: "25px",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1080"
                viewBox="0 0 1080 1080"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_689_2711)">
                    <rect width="1080" height="1080" fill={color1} />
                    <path
                        d="M601.44 891V918.096M614.88 904.548H588H614.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M601.44 957.697V984.793M614.88 971.245H588H614.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M601.44 1022.31V1049.41M614.88 1035.86H588H614.88Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M601.44 891V918.096M614.88 904.548H588H614.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M601.44 957.697V984.793M614.88 971.245H588H614.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M601.44 1022.31V1049.41M614.88 1035.86H588H614.88Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 891V918.096M676.914 904.548H650.034H676.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 957.697V984.793M676.914 971.245H650.034H676.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 1022.31V1049.41M676.914 1035.86H650.034H676.914Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 891V918.096M676.914 904.548H650.034H676.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 957.697V984.793M676.914 971.245H650.034H676.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M663.474 1022.31V1049.41M676.914 1035.86H650.034H676.914Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M725.499 957.697V984.793M738.939 971.245H712.059H738.939Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M725.499 1022.31V1049.41M738.939 1035.86H712.059H738.939Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M725.499 957.697V984.793M738.939 971.245H712.059H738.939Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M725.499 1022.31V1049.41M738.939 1035.86H712.059H738.939Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M787.533 957.697V984.793M800.973 971.245H774.093H800.973Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M787.533 1022.31V1049.41M800.973 1035.86H774.093H800.973Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M787.533 957.697V984.793M800.973 971.245H774.093H800.973Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M787.533 1022.31V1049.41M800.973 1035.86H774.093H800.973Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M849.554 957.697V984.793M862.994 971.245H836.114H862.994Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M849.554 1022.31V1049.41M862.994 1035.86H836.114H862.994Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M849.554 957.697V984.793M862.994 971.245H836.114H862.994Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M849.554 1022.31V1049.41M862.994 1035.86H836.114H862.994Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M67.0122 -97.3139C132.229 -63.4627 167.756 12.0514 148.415 86.1136C127.501 166.203 49.8161 216.366 -30.2042 204.739"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M86.3164 -141.185C172.683 -97.9682 220.067 1.06949 194.694 98.2307C167.426 202.65 65.7493 267.885 -38.6535 251.913"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M48.9791 -53.4492C92.6863 -28.6195 116.112 23.2737 102.804 74.2367C88.2013 130.154 34.4514 165.466 -21.4103 158.395"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        d="M30.7374 -9.57103C53.207 5.97098 64.728 34.3955 57.4186 62.3858C49.0872 94.289 19.066 114.764 -12.6836 112.169"
                        stroke={color3}
                        stroke-width="8"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M980.117 181.227C981.648 179.634 984.18 179.585 985.772 181.116L1009 203.45L1032.23 181.116C1033.82 179.585 1036.35 179.634 1037.88 181.227C1039.41 182.819 1039.36 185.351 1037.77 186.883L1013.16 210.55C1010.84 212.783 1007.16 212.783 1004.84 210.55L980.228 186.883C978.635 185.351 978.585 182.819 980.117 181.227ZM980.117 146.227C981.648 144.634 984.18 144.585 985.772 146.116L1009 168.45L1032.23 146.116C1033.82 144.585 1036.35 144.634 1037.88 146.227C1039.41 147.819 1039.36 150.351 1037.77 151.883L1013.16 175.55C1010.84 177.783 1007.16 177.783 1004.84 175.55L980.228 151.883C978.635 150.351 978.585 147.819 980.117 146.227ZM985.772 111.116C984.18 109.585 981.648 109.634 980.117 111.227C978.585 112.819 978.635 115.351 980.228 116.883L1004.84 140.55C1007.16 142.783 1010.84 142.783 1013.16 140.55L1037.77 116.883C1039.36 115.351 1039.41 112.819 1037.88 111.227C1036.35 109.634 1033.82 109.585 1032.23 111.116L1009 133.45L985.772 111.116ZM980.117 76.2267C981.648 74.6343 984.18 74.5847 985.772 76.1158L1009 98.45L1032.23 76.1158C1033.82 74.5847 1036.35 74.6343 1037.88 76.2267C1039.41 77.8192 1039.36 80.3513 1037.77 81.8825L1013.16 105.55C1010.84 107.783 1007.16 107.783 1004.84 105.55L980.228 81.8825C978.635 80.3513 978.585 77.8192 980.117 76.2267ZM985.772 41.1158C984.18 39.5847 981.648 39.6343 980.117 41.2267C978.585 42.8192 978.635 45.3513 980.228 46.8825L1004.84 70.5496C1007.16 72.7829 1010.84 72.7829 1013.16 70.5496L1037.77 46.8825C1039.36 45.3513 1039.41 42.8192 1037.88 41.2267C1036.35 39.6343 1033.82 39.5847 1032.23 41.1158L1009 63.45L985.772 41.1158Z"
                        fill={color2}
                    />
                    <path
                        d="M1062.52 587.406V560.311M1049.08 573.858H1075.96H1049.08Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1062.52 520.707V493.611M1049.08 507.159H1075.96H1049.08Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1062.52 456.096V429M1049.08 442.548H1075.96H1049.08Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1062.52 587.406V560.311M1049.08 573.858H1075.96H1049.08Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1062.52 520.707V493.611M1049.08 507.159H1075.96H1049.08Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1062.52 456.096V429M1049.08 442.548H1075.96H1049.08Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1000.5 520.707V493.611M987.058 507.159H1013.94H987.058Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1000.5 456.096V429M987.058 442.548H1013.94H987.058Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1000.5 520.707V493.611M987.058 507.159H1013.94H987.058Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M1000.5 456.096V429M987.058 442.548H1013.94H987.058Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M938.462 520.707V493.611M925.022 507.159H951.902H925.022Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M938.462 456.096V429M925.022 442.548H951.902H925.022Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M938.462 520.707V493.611M925.022 507.159H951.902H925.022Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M938.462 456.096V429M925.022 442.548H951.902H925.022Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M876.44 520.707V493.611M863 507.159H889.879H863Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M876.44 456.096V429M863 442.548H889.879H863Z"
                        stroke="#8BD2BD"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M876.44 520.707V493.611M863 507.159H889.879H863Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M876.44 456.096V429M863 442.548H889.879H863Z"
                        stroke="#1D262D"
                        stroke-width="2"
                        stroke-miterlimit="10"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M110.978 1034.52C109.721 1033.82 109.576 1032.57 110.653 1031.74L137.284 1011.28C138.246 1010.55 138.246 1009.45 137.284 1008.72L110.653 988.258C109.576 987.431 109.721 986.185 110.978 985.475C112.235 984.766 114.128 984.862 115.205 985.689L141.835 1006.15C144.722 1008.36 144.722 1011.64 141.835 1013.85L115.205 1034.31C114.128 1035.14 112.235 1035.23 110.978 1034.52ZM71.0126 1034.52C69.7557 1033.82 69.6101 1032.57 70.6875 1031.74L97.3177 1011.28C98.2799 1010.55 98.2799 1009.45 97.3177 1008.72L70.6875 988.258C69.6101 987.431 69.7557 986.185 71.0126 985.475C72.2695 984.766 74.1618 984.862 75.2391 985.689L101.869 1006.15C104.756 1008.36 104.756 1011.64 101.869 1013.85L75.2391 1034.31C74.1618 1035.14 72.2695 1035.23 71.0126 1034.52ZM30.7217 1031.74C29.6443 1032.57 29.7899 1033.82 31.0468 1034.52C32.3037 1035.23 34.196 1035.14 35.2733 1034.31L61.9036 1013.85C64.79 1011.64 64.79 1008.36 61.9036 1006.15L35.2733 985.689C34.196 984.862 32.3037 984.766 31.0468 985.475C29.7899 986.185 29.6443 987.431 30.7217 988.258L57.3519 1008.72C58.3141 1009.45 58.3141 1010.55 57.3519 1011.28L30.7217 1031.74Z"
                        fill={color2}
                    />
                    <path
                        d="M269 881C269 847.863 295.863 821 329 821C362.137 821 389 847.863 389 881C389 914.137 362.137 941 329 941C295.863 941 269 914.137 269 881Z"
                        stroke={color3}
                        stroke-width="30"
                    />
                    <circle
                        cx="164"
                        cy="951"
                        r="20"
                        transform="rotate(-180 164 951)"
                        fill={color3}
                    />
                    <circle
                        cx="174"
                        cy="779"
                        r="27.5"
                        transform="rotate(-180 174 779)"
                        stroke={color3}
                        stroke-width="5"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_689_2711">
                        <rect width="1080" height="1080" fill={color3} />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
