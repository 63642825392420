import React from "react";
import {
    loadFont,
    marketingCreativeColorSchemes,
    marketingCreativeTemplateKeys,
} from "ui/pages/marketing/MarketingCreatives/data";

export const ThiverStorySimple = (props) => {
    const { textData, coverImage } = props;

    const defaultColorScheme =
        marketingCreativeColorSchemes?.[marketingCreativeTemplateKeys.Thiver]
            ?.schemes?.[0];

    const color1 = props?.colors?.[0] || defaultColorScheme?.[0];
    const color2 = props?.colors?.[1] || defaultColorScheme?.[1];
    const color3 = props?.colors?.[2] || defaultColorScheme?.[2];

    React.useEffect(() => {
        loadFont(
            "https://fonts.googleapis.com/css2?family=Ubuntu&display=swap"
        );
    }, []);

    return (
        <div style={{ fontFamily: "Ubuntu" }}>
            {textData?.heading ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        bottom: "755px",
                        right: "34px",
                        fontWeight: 700,
                        fontStyle: "italic",
                        fontSize: "100px",
                        width: "716px",
                        wordBreak: "break-word",
                        textTransform: "uppercase",
                        zIndex: 1,
                    }}
                >
                    {textData?.heading}
                </div>
            ) : null}
            {textData?.sub_heading ? (
                <div
                    style={{
                        position: "absolute",
                        backgroundColor: color2,
                        color: "#0D0C0C",
                        top: "1178px",
                        right: "34px",
                        fontWeight: 400,
                        fontSize: "36px",
                        width: "716px",
                        wordBreak: "break-word",
                        zIndex: 1,
                    }}
                >
                    {textData?.sub_heading}
                </div>
            ) : null}
            {textData?.action_text ? (
                <div
                    style={{
                        position: "absolute",
                        color: color2,
                        top: "108px",
                        right: "34px",
                        fontWeight: 700,
                        fontStyle: "italic",
                        fontSize: "64px",
                        wordBreak: "break-word",
                        textTransform: "uppercase",
                        zIndex: 1,
                        width: "482px",
                    }}
                >
                    {textData?.action_text}
                </div>
            ) : null}
            {coverImage ? (
                <div
                    style={{
                        position: "absolute",
                        width: "551px",
                        height: "1250px",
                        top: "335px",
                        left: "137px",
                        backgroundImage: "url(" + coverImage + ")",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                    }}
                />
            ) : null}

            <svg
                width="1080"
                height="1920"
                viewBox="0 0 1080 1920"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clip-path="url(#clip0_59_133)">
                    <rect width="1080" height="1920" fill={color1} />
                    <path
                        d="M20.7623 560.285C16.5577 556.827 14 550.732 14 544.17L14 445.679C14 430.813 26.3116 421.727 35.8377 429.563L149.462 523.029C154.079 526.827 159.921 526.827 164.538 523.029L278.162 429.563C287.688 421.727 300 430.813 300 445.679V544.17C300 550.732 297.442 556.827 293.238 560.285L164.538 666.152C159.921 669.949 154.079 669.949 149.462 666.152L20.7623 560.285Z"
                        fill={color3}
                    />
                    <path
                        d="M20.7623 346.943C16.5577 343.484 14 337.389 14 330.828L14 232.336C14 217.47 26.3116 208.385 35.8377 216.221L149.462 309.686C154.079 313.484 159.921 313.484 164.538 309.686L278.162 216.221C287.688 208.385 300 217.47 300 232.336V330.828C300 337.389 297.442 343.484 293.238 346.943L164.538 452.809C159.921 456.607 154.079 456.607 149.462 452.809L20.7623 346.943Z"
                        fill={color3}
                    />
                    <path
                        d="M20.7623 133.601C16.5577 130.142 14 124.047 14 117.485L14 18.9939C14 4.12804 26.3116 -4.95743 35.8377 2.87859L149.462 96.344C154.079 100.142 159.921 100.142 164.538 96.344L278.162 2.87859C287.688 -4.95743 300 4.12801 300 18.9939V117.485C300 124.047 297.442 130.142 293.238 133.601L164.538 239.467C159.921 243.265 154.079 243.265 149.462 239.467L20.7623 133.601Z"
                        fill={color3}
                    />
                    <rect
                        x="60"
                        y="108"
                        width="480"
                        height="1704"
                        fill={color2}
                    />
                    <path
                        d="M84.1552 389.951C82.5302 388.613 81.5 386.208 81.5 383.568L81.5 342.198C81.5 339.208 82.7363 336.854 84.3796 335.64C85.9993 334.444 88.0357 334.326 89.8448 335.815L137.519 375.074C139.641 376.821 142.359 376.821 144.481 375.074L192.155 335.815C193.964 334.326 196.001 334.444 197.62 335.64C199.264 336.854 200.5 339.208 200.5 342.198V383.568C200.5 386.208 199.47 388.613 197.845 389.951L143.845 434.418C142.092 435.861 139.908 435.861 138.155 434.418L84.1552 389.951ZM84.1552 300.34C82.5302 299.002 81.5 296.598 81.5 293.958L81.5 252.588C81.5 249.598 82.7363 247.244 84.3796 246.03C85.9993 244.833 88.0357 244.715 89.8448 246.205L137.519 285.464C139.641 287.211 142.359 287.211 144.481 285.464L192.155 246.205C193.964 244.715 196.001 244.833 197.62 246.03C199.264 247.244 200.5 249.598 200.5 252.588V293.958C200.5 296.598 199.47 299.002 197.845 300.34L143.845 344.807C142.092 346.251 139.908 346.251 138.155 344.807L84.1552 300.34ZM84.1552 210.73C82.5302 209.392 81.5 206.988 81.5 204.347L81.5 162.978C81.5 159.988 82.7363 157.634 84.3796 156.42C85.9994 155.223 88.0357 155.105 89.8448 156.595L137.519 195.853C139.641 197.601 142.359 197.601 144.481 195.853L192.155 156.595C193.964 155.105 196.001 155.223 197.62 156.42C199.264 157.634 200.5 159.988 200.5 162.978V204.347C200.5 206.988 199.47 209.392 197.845 210.73L143.845 255.197C142.092 256.64 139.908 256.64 138.155 255.197L84.1552 210.73Z"
                        stroke="#0D0C0C"
                        stroke-linecap="round"
                    />
                    <path
                        d="M197.845 1530.05C199.47 1531.39 200.5 1533.79 200.5 1536.43L200.5 1577.8C200.5 1580.79 199.264 1583.15 197.62 1584.36C196.001 1585.56 193.964 1585.67 192.155 1584.18L144.481 1544.93C142.359 1543.18 139.641 1543.18 137.519 1544.93L89.8448 1584.18C88.0357 1585.67 85.9993 1585.56 84.3796 1584.36C82.7363 1583.15 81.5 1580.79 81.5 1577.8V1536.43C81.5 1533.79 82.5302 1531.39 84.1552 1530.05L138.155 1485.58C139.908 1484.14 142.092 1484.14 143.845 1485.58L197.845 1530.05ZM197.845 1619.66C199.47 1621 200.5 1623.4 200.5 1626.04L200.5 1667.41C200.5 1670.4 199.264 1672.76 197.62 1673.97C196.001 1675.17 193.964 1675.28 192.155 1673.79L144.481 1634.54C142.359 1632.79 139.641 1632.79 137.519 1634.54L89.8448 1673.79C88.0357 1675.28 85.9993 1675.17 84.3796 1673.97C82.7363 1672.76 81.5 1670.4 81.5 1667.41V1626.04C81.5 1623.4 82.5302 1621 84.1552 1619.66L138.155 1575.19C139.908 1573.75 142.092 1573.75 143.845 1575.19L197.845 1619.66ZM197.845 1709.27C199.47 1710.61 200.5 1713.01 200.5 1715.65L200.5 1757.02C200.5 1760.01 199.264 1762.37 197.62 1763.58C196.001 1764.78 193.964 1764.89 192.155 1763.4L144.481 1724.15C142.359 1722.4 139.641 1722.4 137.519 1724.15L89.8448 1763.4C88.0357 1764.89 85.9993 1764.78 84.3796 1763.58C82.7363 1762.37 81.5 1760.01 81.5 1757.02V1715.65C81.5 1713.01 82.5302 1710.61 84.1552 1709.27L138.155 1664.8C139.908 1663.36 142.092 1663.36 143.845 1664.8L197.845 1709.27Z"
                        stroke="#0D0C0C"
                        stroke-linecap="round"
                    />
                    <path
                        d="M1121.47 1484.72C1124.53 1487.41 1126.39 1492.14 1126.39 1497.24L1126.39 1573.76C1126.39 1585.31 1117.43 1592.37 1110.5 1586.28L1027.84 1513.67C1024.49 1510.72 1020.24 1510.72 1016.88 1513.67L934.22 1586.28C927.291 1592.37 918.334 1585.31 918.334 1573.76V1497.24C918.334 1492.14 920.195 1487.41 923.254 1484.72L1016.88 1402.48C1020.24 1399.53 1024.49 1399.53 1027.84 1402.48L1121.47 1484.72Z"
                        fill={color3}
                    />
                    <path
                        d="M1121.47 1650.46C1124.53 1653.15 1126.39 1657.89 1126.39 1662.98L1126.39 1739.5C1126.39 1751.05 1117.43 1758.11 1110.5 1752.02L1027.84 1679.41C1024.49 1676.46 1020.24 1676.46 1016.88 1679.41L934.22 1752.02C927.291 1758.11 918.334 1751.05 918.334 1739.5V1662.98C918.334 1657.89 920.195 1653.15 923.254 1650.46L1016.88 1568.22C1020.24 1565.27 1024.49 1565.27 1027.84 1568.22L1121.47 1650.46Z"
                        fill={color3}
                    />
                    <path
                        d="M1121.47 1816.21C1124.53 1818.89 1126.39 1823.63 1126.39 1828.73L1126.39 1905.24C1126.39 1916.79 1117.43 1923.85 1110.5 1917.76L1027.84 1845.15C1024.49 1842.2 1020.24 1842.2 1016.88 1845.15L934.22 1917.76C927.291 1923.85 918.334 1916.79 918.334 1905.24V1828.73C918.334 1823.63 920.195 1818.89 923.254 1816.21L1016.88 1733.96C1020.24 1731.01 1024.49 1731.01 1027.84 1733.96L1121.47 1816.21Z"
                        fill={color3}
                    />
                    <path
                        d="M1101.81 1447.96C1104.34 1450.18 1106.05 1454.3 1106.05 1458.98L1106.05 1535.5C1106.05 1540.79 1104 1544.81 1101.47 1546.8C1100.22 1547.79 1098.88 1548.26 1097.59 1548.25C1096.31 1548.24 1094.9 1547.76 1093.49 1546.51L1010.83 1473.9C1008.83 1472.14 1006.47 1471.19 1004.03 1471.19C1001.58 1471.19 999.225 1472.14 997.224 1473.9L914.566 1546.51C913.151 1547.76 911.744 1548.24 910.465 1548.25C909.172 1548.26 907.834 1547.79 906.582 1546.8C904.054 1544.81 902 1540.79 902 1535.5V1458.98C902 1454.3 903.718 1450.18 906.239 1447.96L999.864 1365.72C1001.22 1364.52 1002.67 1364 1004.03 1364C1005.39 1364 1006.83 1364.52 1008.19 1365.72L1101.81 1447.96ZM1101.81 1613.7C1104.34 1615.92 1106.05 1620.04 1106.05 1624.72L1106.05 1701.24C1106.05 1706.53 1104 1710.55 1101.47 1712.54C1100.22 1713.53 1098.88 1714 1097.59 1713.99C1096.31 1713.98 1094.9 1713.5 1093.49 1712.26L1010.83 1639.64C1008.83 1637.89 1006.47 1636.93 1004.03 1636.93C1001.58 1636.93 999.225 1637.89 997.224 1639.64L914.566 1712.26C913.151 1713.5 911.744 1713.98 910.465 1713.99C909.172 1714 907.834 1713.53 906.582 1712.54C904.054 1710.55 902 1706.53 902 1701.24V1624.72C902 1620.04 903.718 1615.92 906.239 1613.7L999.864 1531.46C1001.22 1530.27 1002.67 1529.74 1004.03 1529.74C1005.39 1529.74 1006.83 1530.27 1008.19 1531.46L1101.81 1613.7ZM1101.81 1779.45C1104.34 1781.66 1106.05 1785.79 1106.05 1790.46L1106.05 1866.98C1106.05 1872.28 1104 1876.29 1101.47 1878.28C1100.22 1879.27 1098.88 1879.75 1097.59 1879.74C1096.31 1879.73 1094.9 1879.24 1093.49 1878L1010.83 1805.39C1008.83 1803.63 1006.47 1802.68 1004.03 1802.68C1001.58 1802.68 999.225 1803.63 997.224 1805.39L914.566 1878C913.151 1879.24 911.744 1879.73 910.465 1879.74C909.172 1879.75 907.834 1879.27 906.582 1878.28C904.054 1876.29 902 1872.28 902 1866.98V1790.46C902 1785.79 903.718 1781.66 906.239 1779.45L999.864 1697.2C1001.22 1696.01 1002.67 1695.49 1004.03 1695.49C1005.39 1695.49 1006.83 1696.01 1008.19 1697.2L1101.81 1779.45Z"
                        stroke={color2}
                        stroke-width="4"
                        stroke-linecap="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 1647L591 1692H642.962L616.981 1647ZM616.981 1655L597.928 1688H636.033L616.981 1655Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 1707L591 1752H642.962L616.981 1707ZM616.981 1715L597.928 1748H636.033L616.981 1715Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 1767L642.962 1812H591L616.981 1767ZM597.928 1808L616.981 1775L636.033 1808H597.928Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 273L642.961 228H591L616.981 273ZM616.981 265L636.033 232H597.928L616.981 265Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 213L642.961 168H591L616.981 213ZM616.981 205L636.033 172H597.928L616.981 205Z"
                        fill={color2}
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M616.981 153L591 108H642.961L616.981 153ZM636.033 112L616.981 145L597.928 112H636.033Z"
                        fill={color2}
                    />
                </g>
                <defs>
                    <clipPath id="clip0_59_133">
                        <rect width="1080" height="1920" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};
